import React from 'react';

class Prefs extends React.Component {
   render() {
      return (
         <div className="prefs">
            <h1>Preferences!</h1>
         </div>
      );
   }
}

export default Prefs;