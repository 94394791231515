import React from 'react';
import {
   Link,
 } from "react-router-dom";

import './header.css';

export default function LogHeader() {

   return (
      <div className="header-wrapper">
         <div className="header-filler-front"></div>
         <Link to="/dashboard">
            <div className="header-item">
               Home
            </div>
         </Link>
         <Link to="/preferences">
         <div className="header-item">
               Preferences
         </div>
         </Link>
         <div className="header-filler-back"></div>
      </div>
   );
}