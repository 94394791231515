import React from 'react';
import Calendar from '../calendar/calendar'

import './dash.css'

class Dash extends React.Component {
   render() {
      return (
         <div className="dash-wrapper">
            <div className="dash-column">
            <h1>Dashboard!</h1>
            <Calendar token = {this.props.token}/>
            </div>
         </div>
      );
   }
}

export default Dash;