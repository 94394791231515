import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from '@fullcalendar/interaction';

import Reservation from '../reservation/reservation';

import './calendar.css';

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
  }

  state = {
    weekendsVisible: true,
    currentEvents: [],
    editReservation: '',
    editorVisibility: false,
    editingNew: false,
  };

  render() {
    return (
    <div>
      <div className="calendar-wrapper">
        <div className="calendar-container">
          <FullCalendar
              ref={this.calendarRef}
              plugins={[dayGridPlugin, interactionPlugin]}
              height= {600}
              initialView="dayGridMonth"
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              weekends={true}
              firstDay = {1}
              events = {this.fetchEvents}
              eventContent={renderEventContent}
              select={this.handleDateSelect}
              eventClick={this.handleEventClick}
              eventsSet={this.handleEvents}
          />
          </div>
      </div>
      <Reservation 
        newReservation = {this.state.editingNew} 
        visible={this.state.editorVisibility} 
        reservation={this.state.editReservation}
        close={this.closeEditor}
        updateReservation={this.updateEditor}
        removeReservation={this.handleDelete}
        saveReservation={this.handleSave}/>
    </div>
    );
  }

  handleDateSelect = (selectInfo) => {
    this.setState({
      editingNew: true,
      editorVisibility: true,
      editReservation: {
        title: 'test',
        start: new Date(selectInfo.startStr),
        end: new Date(selectInfo.endStr),
        allDay: true,
      },
    });
    selectInfo.view.calendar.unselect();
  };

  handleEventClick = (clickInfo) => {
    this.setState({
      editingNew: false,
      editorVisibility: true,
      editReservation: clickInfo.event,
    });
  };

  handleEvents = (events) => {
    this.setState({
      currentEvents: events,
    });
  };

  fetchEvents = async (info, successCallback, failureCallback) => {
    return fetch(process.env.REACT_APP_API+'/reservation/range?start='+info.start+'&end='+info.end, {
      method: 'GET',
      headers: new Headers({
      Authorization: 'Bearer '+this.props.token})
    })
    .then(response => response.json())
    .then(data => successCallback(data))
    .catch((error) => failureCallback(error))
  }

  closeEditor = () => {
    this.setState({
      editingNew: false,
      editorVisibility: false,
      editReservation: '',
    });
  }

  updateEditor = (update) => {
    this.setState((state) => {
      return {editReservation: {
        id: state.editReservation.id,
        title: state.editReservation.title,
        start: new Date(state.editReservation.start),
        end: new Date(state.editReservation.end),
        allDay: true,
        ...update
      }}
    });
  }

  handleSave = async () => {
    if (this.state.editReservation.id){
      const id = this.state.editReservation.id;
      await updateReservation({token: this.props.token, reservation : {'reservation': this.state.editReservation}},(response) => {
        const calendarAPI = this.calendarRef.current.getApi().view.calendar
        calendarAPI.getEventById(id).remove();
        calendarAPI.addEvent( response.reservation);
        this.closeEditor();
      },()=>{})
    } else {
      await postReservation({token: this.props.token, reservation : {'reservation': this.state.editReservation}},(response) => {
        const calendarAPI = this.calendarRef.current.getApi().view.calendar
        calendarAPI.addEvent( response.reservation);
        this.closeEditor();
      },()=>{})
    }
  }

  handleDelete = async (id) => {
    await deleteReservation({token: this.props.token, reservation : {'reservation': {id: id}}},(response) => {
      const calendarAPI = this.calendarRef.current.getApi().view.calendar
      calendarAPI.getEventById(id).remove();
      this.closeEditor();
    },()=>{})
  }
}

function renderEventContent(eventInfo) {
    return (
      <>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }

async function postReservation(info, successCallback, failureCallback) {
  return fetch(process.env.REACT_APP_API+'/reservation', {
    method: 'POST',
    headers: new Headers({
    'Authorization': 'Bearer '+ info.token,
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    }),
    body: JSON.stringify(info.reservation)
    }).then(data => data.json())
    .then(data => successCallback(data))
    .catch((error) => failureCallback(error))
  }

  async function updateReservation(info, successCallback, failureCallback) {
    return fetch(process.env.REACT_APP_API+'/reservation/update', {
      method: 'POST',
      headers: new Headers({
      'Authorization': 'Bearer '+ info.token,
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      }),
      body: JSON.stringify(info.reservation)
      }).then(data => data.json())
      .then(data => successCallback(data))
      .catch((error) => failureCallback(error))
    }

async function deleteReservation(info, successCallback, failureCallback) {
  return fetch(process.env.REACT_APP_API+'/reservation', {
    method: 'DELETE',
    headers: new Headers({
    'Authorization': 'Bearer '+ info.token,
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    }),
    body: JSON.stringify(info.reservation)
    }).then(response => successCallback(response))
    .catch((error) => failureCallback(error))
  }