import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Dash from '../dash/dash';
import Prefs from '../prefs/prefs';
import Login from '../login/login';
import Signup from '../signup/signup';
import Header from '../header/header';
import useToken from './useToken';

import './app.css';

function App() {
  const { token, setToken } = useToken();

  if(!token) {
    return (
    <div className="main-login-wrapper">
      <h1>Login system</h1>
      <BrowserRouter>
        <Switch>
          <Route path="/signup"><Signup setToken={setToken} /></Route>
          <Route path="/login"><Login setToken={setToken} /></Route>
          <Route path="/"><Login setToken={setToken} /></Route>
        </Switch>
      </BrowserRouter>
    </div>
    );
  }

  return (
    <div className="main-wrapper">
      <BrowserRouter>
        <Header />
        <Switch>
          <Route path="/dashboard">
            <Dash token={token} />
          </Route>
          <Route path="/preferences">
            <Prefs />
          </Route>
          <Route path="/">
            <Dash token={token}/>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;