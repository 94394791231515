import React, { useState } from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import './signup.css';

async function signupUser(credentials) {
 return fetch(process.env.REACT_APP_API + '/user/signup', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json',
     'Cache-Control': 'no-cache',
   },
   body: JSON.stringify(credentials)
 })
   .then(data => data.json())
}

export default function Signup({ setToken }) {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await signupUser({ 'user': {
        name,
        email,
        password
        }
    });
    setToken(token);
    <Redirect to="/" />;
  }

  return(
    <div className="signup-wrapper">
      <h1>Create account</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Email</p>
          <input type="text" onChange={e => setEmail(e.target.value)}/>
        </label>
        <label>
          <p>Name</p>
          <input type="text" onChange={e => setName(e.target.value)}/>
        </label>
        <label>
          <p>Password</p>
          <input type="password" onChange={e => setPassword(e.target.value)}/>
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}

Signup.propTypes = {
  setToken: PropTypes.func.isRequired
};