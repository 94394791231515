import React from "react";

import "./reservation.css";

export default class Reservation extends React.Component {
  hideReservation = () => {
    this.props.close();
  };

  handleDelete = (event) => {
    event.preventDefault();
    this.props.removeReservation(this.props.reservation.id);
  }

  handleSave = (event) => {
    event.preventDefault();
    this.props.saveReservation();
  }

  handleChange = (event) => {
    event.preventDefault();
    this.props.updateReservation({[event.target.id]: event.target.value});
  }

  handleChangeDate = (event) => {
    event.preventDefault();
    let d = new Date(event.target.value);
    d.setHours(2, 0, 0);
    this.props.updateReservation({[event.target.id]: new Date(event.target.value)});
  }

  handleChangeEndDate = (event) => {
    event.preventDefault();
    let d = new Date(event.target.value);
    d.setDate(d.getDate()+1);
    d.setHours(2, 0, 0);
    this.props.updateReservation({[event.target.id]: d});
  }

  displayDate = () => {
    let d = new Date(this.props.reservation.start);
    d.setHours(2, 0, 0);
    return d.toISOString().slice(0, 10)
  }

  displayEndDate = () => {
    let d = new Date(this.props.reservation.end);
    d.setDate(d.getDate()-1);
    d.setHours(2, 0, 0);
    return d.toISOString().slice(0, 10)
  }

  render() {
    return (
      <>{
        this.props.visible ?
        <div className='overlayBackground'>
          <div className="reservation-wrapper">
            <div className="reservation-header"><button onClick={this.hideReservation} className="close-icon"></button></div>
            <div className="reservation-container">
              <h2>Reservation!</h2>
              <form autoComplete="off">
              <label>
                <p>Title</p>
                <input type="text" id="title" value={this.props.reservation.title} onChange={this.handleChange}/>
              </label>
              <label>
              <p>Start</p>
              <input type="date" id="start" value={this.displayDate()} onChange={this.handleChangeDate}></input>
              </label>
              <label>
              <p>End</p>
              <input type="date" id="end" value={this.displayEndDate()} onChange={this.handleChangeEndDate}></input>
              </label>
              <div className = "reservationButtons">
                {this.props.newReservation? null :
                <button onClick={this.handleDelete}>Delete</button>
                }
                <button onClick={this.handleSave}>Save</button>
              </div>
              </form>
            </div>
          </div>
        </div>
        : null
        }
      </>
    );
  }
}